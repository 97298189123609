import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { MailerLiteEmbed } from '../../components/mailerlite-embed';
import { Seo } from '../../components/seo';

const FiveProjectsEbook = ({ location }) => {
  const pageTitle = '5 Projects to Become Qualified as a Web Developer';
  return (
    <Layout pageType="landing" pageTitle={pageTitle}>
      <Seo
        pageTitle={pageTitle}
        description="Getting your first web development gig is hard. This ebook will take you from zero to pro in 5 projects."
        pathname={location.pathname}
      />
      <Article>
        <h1>“I still can't get a dev job.”</h1>
        <p>
          Everywhere you look, you see{' '}
          <strong>“we can’t hire enough developers.”</strong>
        </p>
        <p>
          Yet here you are – a newly minted developer, ripe for the picking –
          but <strong>utterly unable to land a job</strong>, no matter what you
          try.
        </p>
        <p>
          And you’ve tried hard! Shotgunning resumes, rolling the dice with each
          application to jobs that want 3-5 years of experience (while you have
          none), hoping for some company to take a chance on you.
        </p>
        <h2>It wasn't supposed to be like this.</h2>
        <p>
          You thought you’d finish up your education and get a few amazing
          offers on your way out the door. You could play them against each
          other to maximize your starting salary and then hole up at your desk,
          doing nothing but writing code 8 hours a day.
        </p>

        <p>
          Then you were gonna sit back in your Aeron chair enjoying the cushy
          developer life:
        </p>

        <ul>
          <li>💰 the tip-top salaries</li>
          <li>🏥 the fully paid healthcare</li>
          <li>🥘 the chef-prepared lunches</li>
        </ul>

        <p>
          What they didn’t tell you is that everyone else had the same idea.
          They all saw the pay and the benefits and set out to change their
          careers, too.
        </p>

        <p>
          The other thing they didn’t tell you: remember all those unfilled
          positions?{' '}
          <strong>Those are all at the junior and senior level.</strong>
        </p>
        <h2>You need a different approach.</h2>
        <p>
          This is how I did it a few years ago when I got started as an old dude
          (relative to the industry) with no experience, no network, and without
          a fancy degree.
        </p>
        <p>
          If you could skip the glut of entry-level developers, you could build
          an awesome career for yourself. You just need to find a way to start
          with some actual experience.
        </p>
        <p>
          This is how I did it a few years ago when I got started as an old dude
          (relative to the industry) with no experience, no network, and without
          a fancy degree.
        </p>
        <p>
          Don’t get me wrong, I didn’t go into it planning for things to work
          out this way. But I stumbled upon <b>a way to leapfrog entry-level</b>
          as I was struggling to get into the same pipe as everyone else.
        </p>
        <p>I want to help you do it too.</p>
        <p>
          I bottled up my high-level strategy into a free ebook with{' '}
          <strong>
            5 projects you can do to become qualified as a web developer
          </strong>
          . Whether you already know the tech or this is the first day of your
          journey, there’s something in it for you. Share your email to get your
          copy.
        </p>
        <p>
          It’s time to stop rolling the dice and take your career into your own
          hands.
        </p>
        <MailerLiteEmbed
          formUrl="https://dashboard.mailerlite.com/forms/90414/61292475278427680/share"
          height="638px"
        />
      </Article>
    </Layout>
  );
};
export default FiveProjectsEbook;
